import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Table, Button } from "react-bootstrap";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import "../dashboard.scss";
import "./dashboard.css";
import "./state.css";
import { statefeddashboard, statefedupcoming } from "../../../services/state";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import imageUrl from "../../../ImageUrl";
import fileString from "../../../assets/language/filepath.json";
import exportUrl from "../../../Export";
function Statedashboard() {
  const navigate = useNavigate();
  const [filterdate, setFilterData] = useState([]);
  const [s3path, setPath] = useState("");
  const [counts, setCounts] = useState("");

  let arr = [];
  useEffect(() => {
    statefedupcoming().then((res) => {
      if (res.status === true) {
        setPath(res.data.s3path);
        if (res.data.tournament.length !== 0) {
          for (let i = 0; i < res.data.tournament.length; i++) {
            let butt = "";
            if (res.data.tournament[i].sent_status === 1) {
              butt = res.data.tournament[i].sent_status;
            } else {
              if (
                moment(new Date()).format("YYYY-MM-DD") <=
                moment(res.data.tournament[i].entry_deadline_date).format(
                  "YYYY-MM-DD"
                )
              ) {
                if (res.data.tournament[i].sent_status === 1) {
                  butt = res.data.tournament[i].sent_status;
                } else {
                  if (
                    moment(res.data.tournament[i].entry_opens).format(
                      "YYYY-MM-DD"
                    ) <= moment(new Date()).format("YYYY-MM-DD")
                  ) {
                    butt = 0;
                  }
                }
              } else {
                butt = "Entry Closed";
              }
            }
            arr.push({
              id: res.data.tournament[i].id,
              meet_name: res.data.tournament[i].meet_name,
              start_date: res.data.tournament[i].start_date,
              end_date: res.data.tournament[i].end_date,
              entry_opens: res.data.tournament[i].entry_opens,
              entry_deadline_date: res.data.tournament[i].entry_deadline_date,
              sent_status: butt,
              logo: res.data.tournament[i].logo,
              allow_additional_entry:
                res.data.tournament[i].allow_additional_entry,
              additionalentry_status:
                res.data.tournament[i].additionalentry_status,
              circular_file_1: res.data.tournament[i].circular_file_1,
            });
          }
        }
        setFilterData(arr);
      }
    });

    statefeddashboard().then((response) => {
      if (response.status === true) {
        setCounts(response.data);
      }
    });
  }, []);

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/logo/logo-small.png");
    ev.target.onerror = null;
  }

  const columnDefs = [
    {
      headerName: "Logo",
      width: 80,
      resizable: true,
      field: "logo",
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "35px", height: "35px" }}
            src={imageUrl + fileString.tournamentfiles + params.data.logo}
            onError={(e) => errorhandle(e)}
          />
        );
      },
    },
    {
      headerName: "Meet Name",
      width: 290,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Date",
      width: 220,
      field: "start_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      valueGetter: function sumField(params) {
        return (
          moment(params.data.start_date).format("DD-MM-YYYY") +
          " - " +
          moment(params.data.end_date).format("DD-MM-YYYY")
        );
      },
    },

    {
      headerName: "Entry Open",
      width: 145,
      field: "entry_opens",
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== null) {
          return moment(data.value).format("DD-MM-YYYY");
        } else {
          return "-";
        }
      },
    },
    {
      headerName: "Entry Closed",
      width: 145,
      field: "entry_deadline_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Actions",
      width: 120,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        if (params.data.sent_status === 1) {
          return (
            <div>
              <Link
                to={"/state/confirmation/" + params.value}
                className="btn-sm btn-info me-2"
              >
                <i className="fa fa-eye fa-lg"></i>
              </Link>
              {params.data.allow_additional_entry === "Y" &&
                params.data.additionalentry_status === 0 && (
                  <Link
                    to={"/state/additionalathleteentrylist/" + params.value}
                    className="btn-sm btn-success"
                  >
                    <i className="fa fa-user-plus fa-lg"></i>
                  </Link>
                )}
            </div>
          );
        } else if (params.data.sent_status === 0) {
          return (
            <Link
              to={"/state/athleteentrylist/" + params.value}
              className="btn-sm btn-success"
            >
              <i className="fa fa-user-plus fa-lg"></i>
            </Link>
          );
        } else if (params.data.sent_status !== "") {
          return <a style={{ color: "red" }}>{params.data.sent_status}</a>;
        }
      },
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    // rtl: true,

    // nextArrow: <SampleNextArrow1 />,
    // prevArrow: <SamplePrevArrow1 />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Fragment>
      <div id="statedash">
        {/* <!-- Page Header --> */}
        <div className="page-header d-block">
          <div>
            <Card className="card card-aside custom-card">
              <Card.Body>
                {/* <div className="row">
                  <div className="col-md-8"> */}
                <div className="d-flex align-items-center pt-0 mt-auto">
                  <div className="me-3">
                    <Link>
                      <h5 className="main-content-label tx-24 mb-0 tx-medium">
                        Welcome to {localStorage.getItem("User")} Dashboard
                      </h5>
                    </Link>
                  </div>
                  {/* </div>
                  </div>
                  <div className="col-md-4 text-right">
                    <a
                      type="button"
                      className="btn btn-success"
                      onClick={() => navigate("/state/managerform/")}
                    >
                      <i className="fe fe-user me-2"></i> Meet Manager Course Form
                    </a>
                  </div> */}
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        {/* <!-- End Page Header --> */}

        <Card className="custom-card bg-none">
          <Card.Header className="py-0 px-2">
            <div className="page-header mt-1 mb-2">
              {filterdate.length !== 0 && (
                <div>
                  <h2 className="main-content-title tx-24 mg-b-0">
                    Upcoming Meet
                  </h2>
                </div>
              )}
            </div>
          </Card.Header>
          <Card.Body className="h-100 px-2 py-0">
            <Slider {...settings}>
              {filterdate.map((data, i) => {
                return (
                  <div className="item">
                    <Card className="me-3 ">
                      <Card.Body className=" user-card text-center">
                        <div className="mt-2">
                          <h5 className="mb-1">{data.meet_name}</h5>
                        </div>
                        <a
                          href={s3path + data.circular_file_1}
                          download
                          className="btn ripple btn-danger btn-sm mt-3"
                        >
                          <i className="fe fe-download-cloud me-2"></i>Download
                          Circular
                        </a>
                      </Card.Body>
                    </Card>
                  </div>
                );
              })}
            </Slider>
          </Card.Body>
        </Card>

        {/* <!--Row--> */}
        <Row className="row-sm mx-0">
          <Col sm={12} md={12} lg={12} xl={12}>
            <div className="page-header mt-1 mb-2">
              <div>
                <h2 className="main-content-title tx-24 mg-b-0">
                  Athlete Reports
                </h2>
              </div>
              <div className="d-flex">
                <div className="justify-content-center">
                  <a
                    type="button"
                    className="btn my-1 btn-secondary me-2"
                    onClick={() => navigate("/state/reportedlist/")}
                  >
                    <i className="fe fe-user me-2"></i> Reported Athletes
                  </a>
                  <a
                    type="button"
                    className="btn my-1 btn-success"
                    onClick={() =>
                      window.open(
                        exportUrl +
                          "reportprofileexcel/" +
                          localStorage.getItem("Userid"),
                        "_self"
                      )
                    }
                  >
                    <i className="fe fe-download-cloud me-2"></i> Download
                    Report
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={3} lg={3} xl={3}>
            <Card className="custom-card">
              <Card.Body>
                <div className="card-item">
                  <div
                    className="card-item-icon card-icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/state/athletelist/")}
                  >
                    <i className="fe fe-eye tx-20 text-danger"></i>
                  </div>
                  <div className="card-item-title mb-2">
                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                      TOTAL NUMBER OF ATHLETES
                    </label>
                  </div>
                  <div className="card-item-body">
                    <div className="card-item-stat">
                      <h4 className="font-weight-bold">
                        {counts.totalathletes}
                      </h4>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={3} lg={3} xl={3}>
            <Card className="custom-card">
              <Card.Body>
                <div className="card-item">
                  <div
                    className="card-item-icon card-icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/state/maleathletelist/")}
                  >
                    <i className="fe fe-eye tx-20 text-danger"></i>
                  </div>
                  <div className="card-item-title mb-2">
                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                      Total Number of Men
                    </label>
                  </div>
                  <div className="card-item-body">
                    <div className="card-item-stat">
                      <h4 className="font-weight-bold">
                        {counts.total_number_of_men}
                      </h4>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={3} lg={3} xl={3}>
            <Card className="card custom-card">
              <Card.Body>
                <div className="card-item">
                  <div
                    className="card-item-icon card-icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/state/femaleathletelist/")}
                  >
                    <i className="fe fe-eye tx-20 text-danger"></i>
                  </div>
                  <div className="card-item-title  mb-2">
                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                      Total Number of Women
                    </label>
                  </div>
                  <div className="card-item-body">
                    <div className="card-item-stat">
                      <h4 className="font-weight-bold">
                        {counts.total_number_of_women}
                      </h4>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={3} lg={3} xl={3}>
            <Card className="card custom-card">
              <Card.Body>
                <div className="card-item">
                  <div
                    className="card-item-icon card-icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/state/nocathletelist/")}
                  >
                    <i className="fe fe-eye tx-20 text-danger"></i>
                  </div>
                  <div className="card-item-title  mb-2">
                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                      NOC ATHLETES
                    </label>
                  </div>
                  <div className="card-item-body">
                    <div className="card-item-stat">
                      <h4 className="font-weight-bold">{counts.noc}</h4>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={3} lg={3} xl={3}>
            <Card className="card custom-card">
              <Card.Body>
                <div className="card-item">
                  <div
                    className="card-item-icon card-icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/state/overageathletelist/")}
                  >
                    <i className="fe fe-eye tx-20 text-danger"></i>
                  </div>
                  <div className="card-item-title  mb-2">
                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                      OVERAGE ATHLETES
                    </label>
                  </div>
                  <div className="card-item-body">
                    <div className="card-item-stat">
                      <h4 className="font-weight-bold">{counts.overage}</h4>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <!--End row--> */}

        {/* <!-- Row --> */}
        <Row className="row-sm mx-0 mt-3">
          <Col lg={12}>
            <Card className="custom-card overflow-hidden">
              <Card.Header>
                <div className="row">
                  <div className="col-md-8">
                    <h3 className="card-title tx-18 lineheight1 mb-0">
                      <span className="main-content-label tx-18">
                        Meet Lists
                      </span>
                    </h3>
                  </div>
                  <div className="col-md-4 text-right">
                    {/* <a
                      type="button"
                      className="btnscreen"
                      onClick={() => navigate("/state/managerlist/")}
                    >
                      <i className="fe fe-user me-2"></i> Meet Manager Course Registration
                      List
                    </a> */}
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="responsive">
                  <div
                    className="ag-theme-alpine ag-style"
                    style={{
                      height: 600,
                      width: "100%",
                      marginTop: "2rem",
                    }}
                  >
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={filterdate}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

Statedashboard.propTypes = {};

Statedashboard.defaultProps = {};

export default Statedashboard;
