import axios from "axios";
import { Link } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";

export async function meettypelist() {
  try {
    return await axios
      .get(`${baseUrl}/tournamentmeettype`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetagecategory() {
  try {
    return await axios
      .get(`${baseUrl}/age_categories`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetentrylist() {
  try {
    return await axios
      .get(`${baseUrl}/tournamenttype`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meeteventlist() {
  try {
    return await axios
      .get(`${baseUrl}/allevents`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meettourdetails(id, uid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/athletes/tournament-details/${id}/${uid}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

////Payment update
export async function stateindpaymentupdate(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/athletes/state-indmeet-paysuccess`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetdetails(id) {
  try {
    return await axios
      .get(`${baseUrl}/tournamentdetails/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetinformation(id) {
  try {
    return await axios
      .get(`${baseUrl}/tourdetails/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetinfo(id) {
  try {
    return await axios
      .get(`${baseUrl}/tournamentinfo/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetentrytypelist(id) {
  try {
    return await axios
      .get(`${baseUrl}/tournamenttypecategory/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function addmeetentry(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/createtournament`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function updatemeetentry(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/updatemeetbasicinfo`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function agecategorylist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/meetagecategories/${data}`,  {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function agecategoryupdate(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/updateagecategorydetails`,data , {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function agecategoryeventlist(id,ageid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/meetagecategoriesevents/${id}/${ageid}`,  {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function agecategoryeventadd(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/addnewevent`,data,  {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function agecategoryeventdelete(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/removeevent`,data,  {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function meetagelist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/categoriespertournament/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetaddtournament(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/tournamentagecategory`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetlist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/tournamentlist`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetindividuallist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/athletes/listindividualtournamemt`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetregistercheck(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/athletes/meet-registration-check`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function natmeetindividualdelete(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/athletes/remove-natind-entry`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetindividualdelete(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/athletes/remove-ind-entry`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function stateunltlist() {
  try {
   
    return await axios
      .get(`${baseUrl}/state-unit-list`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function addnationalindividual(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/athletes/nationalmeetindividualentry`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function natindccavenuestatus(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/athletes/check-natindpayment-status`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function natindividualconfirmation(tid,uid) {
  try {
      const header = {
          Authorization: 'Bearer ' + localStorage.getItem('Token'),
          'Content-Type': 'application/json',
      };
      return await axios
          .get(`${baseUrl}/nat-individual-pdf/${tid}/${uid}`,{ headers: header })
          .then((response) => {
              return response.data;
          })
          .catch((error) => {
              catcherror(error);
          });
  } catch (error) {
      catcherror(error);
  }
}

export async function addstateindividual(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/athletes/stateindividualentry`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function addstateindividualpayment(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/athletes/state-ind-createorderid`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function stateindividualccavenuepayment(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/athletes/state-ind-paynow`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function stateindividualconfirmation(tid,uid) {
  try {
      const header = {
          Authorization: 'Bearer ' + localStorage.getItem('Token'),
          'Content-Type': 'application/json',
      };
      return await axios
          .get(`${baseUrl}/state-indconfirm-pdf/${tid}/${uid}`)
          .then((response) => {
              return response.data;
          })
          .catch((error) => {
              catcherror(error);
          });
  } catch (error) {
      catcherror(error);
  }
}

////State District Entry Athlete List
export async function statedistrictathletelist(id, name) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/districtunit/districtstateathletelist/${id}/${name}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

///Events based on age category
export async function eventbasedonage(id, catid) {
  try {
    return await axios
      .get(`${baseUrl}/tournamentevents/${id}/${catid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

///Additional Events based on age category
export async function addeventbasedonage(id, catid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/districtunit/tournamentevents-additional/${id}/${catid}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
////Add button in add athlete
export async function statedistrictaddathlete(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/districtunit/addtournamentdistathltete`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

////Additional Add button in add athlete
export async function addstatedistrictaddathlete(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/districtunit/addtournamentdistathltete-additional`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


///Athlete list based  on category (selection)
export async function athletelistbasedcategory(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/districtunit/athletesbasedoncategory`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

///Athlete list based on age category and events
export async function athletelistbasedcategoryevents(id, catid, eid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(
        `${baseUrl}/districtunit/meetregisteredathletes?tournament_meet_id=${id}&category_id=${catid}&event_id=${eid}`,
        {
          headers: header,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

///Additional Athlete list based on age category and events
export async function addathletelistbasedcategoryevents(id, catid, eid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(
        `${baseUrl}/districtunit/meetregisteredathletes-additional?tournament_meet_id=${id}&category_id=${catid}&event_id=${eid}`,
        {
          headers: header,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

///Delete Athlete based  on category and events
export async function athletedeletetbasedcategory(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/districtunit/removeathlete/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

///Delete Athlete based  on category and events
export async function addathletedeletetbasedcategory(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/districtunit/removeathlete-additional/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetstatedistrictlist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/districtunit/districtmeetlist/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

////Summary details in add athlete
export async function districtsummarydetail(id, district) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/districtunit/viewtoursummarypage/${id}/${district}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

////Additional Summary details in add athlete
export async function adddistrictsummarydetail(id, district) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/districtunit/viewtoursummarypage-additional/${id}/${district}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

////Confirmation pdf
export async function districtconpdf(id, district) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/dist-confrimation-pdf/${id}/${district}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
////Payment Status details in Summary
export async function districtpaymentstatus(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/districtunit/check-distpayment-status`, id, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


////Additional Payment Status details in Summary
export async function adddistrictpaymentstatus(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/districtunit/check-distpayment-status-additional`, id, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
////Summary details in razor pay submit and pay athlete
export async function districtcreateorderpayment(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/districtunit/createorderidpaysubmit`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

////Summary details in ccavenue submit and pay athlete
export async function districtccavenuepayment(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/districtunit/state-dist-paynow`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

////Additional Summary details in ccavenue submit and pay athlete
export async function adddistrictccavenuepayment(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/districtunit/state-dist-paynow-additional`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
////State District Entry Get order id payment details in payment page
export async function districtgetorderid(id, distict, state) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(
        `${baseUrl}/districtunit/orderidtableresponse/${id}/${distict}/${state}`,
        {
          headers: header,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

////Payment update
export async function districtpaymentupdate(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/districtunit/updatepaymentsuccess`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}




function catcherror(error) {
  // console.log(error.response)
  if (error.response.status === 401 || error.response.status === 422) {
    localStorage.clear();
    window.location.reload();
    <Link to={"/login"}></Link>;
  } else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
