import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Card, Row, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import imageUrl from "../../../ImageUrl";
import Select from "react-select";
import fileString from "../../../assets/language/filepath.json";

import {
  addathletedeletetbasedcategory,
  addathletelistbasedcategoryevents,
  adddistrictpaymentstatus,
  addeventbasedonage,
  addstatedistrictaddathlete,
  athletedeletetbasedcategory,
  athletelistbasedcategory,
  athletelistbasedcategoryevents,
  districtpaymentstatus,
  eventbasedonage,
  meetdetails,
  statedistrictaddathlete,
} from "../../../services/meetservice";
import "./district.css";
const Districtathlete = () => {
  const [alist, setAthletelist] = useState([]);
  const [aclist, setAthCatlist] = useState([]);
  const [mcnt, setMcnt] = useState([]);

  const [avalue, setAthlete] = useState("");
  const [tour, setTourName] = useState("");
  const [ename, setEventName] = useState("");
  const [ainfo, setAthInfo] = useState([]);

  const [aname, setAthName] = useState([]);
  const [adob, setAthDob] = useState([]);
  const [aimage, setAthImage] = useState([]);

  const [agename, setAgeName] = useState("");
  const [apevent, setAthPerEve] = useState("");
  const [epathlete, setEvePerAth] = useState("");

  const [tlist, setTourlist] = useState([]);
  const [elist, setEventlist] = useState([]);

  var arr = [];
  const { id } = useParams();

  const [show, setShow] = useState(false);
  function setAShow() {
    setAthlete("");
    setAthName("");
    setAthDob("");
    setAthImage("");
    setErrorMessage("");
    setShow(true);
  }

  function setAClose() {
    setAthName("");
    setAthDob("");
    setAthImage("");
    setErrorMessage("");

    setShow(false);
  }

  function selectevent(evt, index) {
    setEventName("");
    setEventName(evt);
    setAthPerEve("");
    setEvePerAth("");
    elist[index].background = "green";

    var eventid = "";
    if (elist.length !== 0) {
      for (let j = 0; j < elist.length; j++) {
        if (elist[j].event_name === evt) {
          eventid = elist[j].event_id;
        } else {
          elist[j].background = "";
        }
      }
      setEventlist([...elist]);
    }

    var ageid = "";
    var aper = "";
    var eper = "";
    if (tlist.length !== 0) {
      for (let j = 0; j < tlist.length; j++) {
        if (tlist[j].age_categories === agename) {
          ageid = tlist[j].id;
          aper = tlist[j].max_athlete_event;
          eper = tlist[j].max_event_athlete;
          setAthPerEve(tlist[j].max_athlete_event);
          setEvePerAth(tlist[j].max_event_athlete);
        }
      }
    }
    if (
      evt === "4*100M Relay" ||
      evt === "4*400M Relay" ||
      evt === "Medley Relay"
    ) {
      if (id === "161" || id === "162") {
        setAthPerEve(4);
        loadlist(ageid, eventid, 4,urllink);
      } else {
        setAthPerEve(6);
        loadlist(ageid, eventid, 6,urllink);
      }
    } else if (evt === "4*100M Mixed Relay") {
      setAthPerEve(3);
      loadlist(ageid, eventid, 3,urllink);
    } else if (evt === "4*400M Mixed Relay") {
      setAthPerEve(3);
      loadlist(ageid, eventid, 3,urllink);
    } else {
      loadlist(ageid, eventid, aper,urllink);
    }
  }
  var arr2 = [];
  const navigate = useNavigate();
  const [urllink, setUrlLink] = useState("");

  useEffect(() => {
    const url = window.location.href;
    const data = url.split("/#/");
    const link = data[1].split("/");
    setUrlLink(link[1]);

    const temp = new FormData();
    temp.append("current_meet_id", id);
    if (link[1] === "additionaladdathlete") {
      adddistrictpaymentstatus(temp).then((response) => {
        if (response.status === true) {
          if (response.data.Payment_Status === "Paid") {
            navigate("/district/additionalconfirmation/" + id);
          }
        }
      });
    } else {
      districtpaymentstatus(temp).then((response) => {
        if (response.status === true) {
          if (response.data.Payment_Status === "Paid") {
            navigate("/district/confirmation/" + id);
          }
        }
      });
    }

    meetdetails(id).then((response) => {
      if (response.status === true) {
        setTourName(response.data.tournament.meet_name);
        arr = [];
        if (response.data.tounamentage.length !== 0) {
          for (let i = 0; i < response.data.tounamentage.length; i++) {
            var act = "tabbtn my-2 me-2";
            if (i == 0) {
              act = "tabbtn active my-2 me-2";
            }
            arr.push({
              id: response.data.tounamentage[i].age_category_id,
              tournament_meet_id:
                response.data.tounamentage[i].tournament_meet_id,
              old_meet_id: response.data.tounamentage[i].old_meet_id,
              max_event_athlete:
                response.data.tounamentage[i].max_event_athlete,
              max_athlete_event:
                response.data.tounamentage[i].max_athlete_event,
              fees: response.data.tounamentage[i].fees,
              events: response.data.tounamentage[i].events,
              dob_start_date: response.data.tounamentage[i].dob_start_date,
              dob_end_date: response.data.tounamentage[i].dob_end_date,
              age_categories: response.data.tounamentage[i].age_categories,
              active: act,
            });
          }
          setTourlist(arr);
        }
        arr2 = [];

        //Event load function
        eventageload(arr[0].id, arr[0].age_categories, link[1]);
      }
    });
  }, []);

  function agelist(value, index) {
    setAthletelist([]);

    if (tlist.length !== 0) {
      for (let i = 0; i < tlist.length; i++) {
        if (index === i) {
          tlist[i].active = "tabbtn active my-2 me-2";
          // setAgeName(tlist[i].age_categories);
          // setAthPerEve(tlist[i].max_athlete_event);
          // setEvePerAth(tlist[i].max_event_athlete);

          //Event load function
          eventageload(tlist[i].id, tlist[i].age_categories, urllink);
        } else {
          tlist[i].active = "tabbtn my-2 me-2";
        }
        setTourlist([...tlist]);
      }
    }
  }

  function deleteathlete(did) {
    if(urllink === "additionaladdathlete"){
      addathletedeletetbasedcategory(did).then((response) => {
        if (response.status === true) {
          var eventid = "";
          if (elist.length !== 0) {
            for (let j = 0; j < elist.length; j++) {
              if (elist[j].event_name === ename) {
                eventid = elist[j].event_id;
              }
            }
          }
  
          var ageid = "";
          if (tlist.length !== 0) {
            for (let j = 0; j < tlist.length; j++) {
              if (tlist[j].age_categories === agename) {
                ageid = tlist[j].id;
              }
            }
          }
          loadlist(ageid, eventid, apevent,urllink);
        }
      });
    }else{
      athletedeletetbasedcategory(did).then((response) => {
        if (response.status === true) {
          var eventid = "";
          if (elist.length !== 0) {
            for (let j = 0; j < elist.length; j++) {
              if (elist[j].event_name === ename) {
                eventid = elist[j].event_id;
              }
            }
          }
  
          var ageid = "";
          if (tlist.length !== 0) {
            for (let j = 0; j < tlist.length; j++) {
              if (tlist[j].age_categories === agename) {
                ageid = tlist[j].id;
              }
            }
          }
          // arr2 = [];
          // if (apevent !== 0) {
          //   var final = apevent;
          //   if (aclist.length !== 0) {
          //     final = apevent - aclist.length;
          //   }
          //   for (let j = 0; j < final; j++) {
          //     arr2.push({
          //       id: j,
          //       load: "",
          //     });
          //   }
          // }
          // setMcnt(arr2);
  
          loadlist(ageid, eventid, apevent,urllink);
        }
      });
    }
    
  }

  function loadlist(aid, tid, max,mlink) {
    setAthCatlist([]);
    if(mlink === "additionaladdathlete"){
      addathletelistbasedcategoryevents(id, aid, tid).then((res) => {
        if (res.status === true) {

          setAthCatlist(res.data);
          arr2 = [];
          var final = max;
          if (res.data.length !== 0) {
            final = max - res.data.length;
          }
          for (let i = 0; i < final; i++) {
            arr2.push({
              id: i,
              load: "",
            });
          }
          setMcnt([...arr2]);
        }
      });
    }else{
      athletelistbasedcategoryevents(id, aid, tid).then((res) => {
        if (res.status === true) {
          setAthCatlist(res.data);
          arr2 = [];
          var final = max;
          if (res.data.length !== 0) {
            final = max - res.data.length;
          }
          for (let i = 0; i < final; i++) {
            arr2.push({
              id: i,
              load: "",
            });
          }
          setMcnt([...arr2]);
        }
      });
    }
    
  }

  var arr1 = [];
  function eventageload(tid, aid, alink) {
    if (alink === "additionaladdathlete") {
      addeventbasedonage(id, tid).then((res) => {
        if (res.status === true) {
          if (res.data.length !== 0) {
            if (
              res.data[0].event_name === "4*100M Relay" ||
              res.data[0].event_name === "4*400M Relay" ||
              res.data[0].event_name === "Medley Relay"
            ) {
              if (id === "161" || id === "162") {
                setAthPerEve(4);
              } else {
                setAthPerEve(6);
              }
            } else if (res.data[0].event_name === "4*100M Mixed Relay") {
              setAthPerEve(3);
            } else if (res.data[0].event_name === "4*400M Mixed Relay") {
              setAthPerEve(3);
            } else {
              setAthPerEve(res.data[0].max_athlete_event);
            }
            setEvePerAth(res.data[0].max_event_athlete);
            setAgeName(res.data[0].age_category_name);
            setEventName(res.data[0].event_name);

            for (let i = 0; i < res.data.length; i++) {
              var clr = "";
              if (i == 0) {
                clr = "green";
              }
              arr1.push({
                event_id: res.data[i].tournament_event_id,
                event_name: res.data[i].event_name,
                id: res.data[i].id,
                max_athlete_event: res.data[i].max_athlete_event,
                max_event_athlete: res.data[i].max_event_athlete,
                age_category_name: res.data[i].age_category_name,
                background: clr,
              });
            }
            setEventlist(arr1);
            if (
              res.data[0].event_name === "4*100M Relay" ||
              res.data[0].event_name === "4*400M Relay" ||
              res.data[0].event_name === "Medley Relay"
            ) {
              if (id === "161" || id === "162") {
                loadlist(tid, res.data[0].tournament_event_id, 4,alink);
              } else {
                loadlist(tid, res.data[0].tournament_event_id, 6,alink);
              }
            } else if (res.data[0].event_name === "4*100M Mixed Relay") {
              loadlist(tid, res.data[0].tournament_event_id, 3,alink);
            } else if (res.data[0].event_name === "4*400M Mixed Relay") {
              loadlist(tid, res.data[0].tournament_event_id, 3,alink);
            } else {
              loadlist(
                tid,
                res.data[0].tournament_event_id,
                res.data[0].max_athlete_event,alink
              );
            }
          }
        }
      });
    } else {
      eventbasedonage(id, tid).then((res) => {
        if (res.status === true) {
          if (res.data.length !== 0) {
            if (
              res.data[0].event_name === "4*100M Relay" ||
              res.data[0].event_name === "4*400M Relay" ||
              res.data[0].event_name === "Medley Relay"
            ) {
              if (id === "161" || id === "162") {
                setAthPerEve(4);
              } else {
                setAthPerEve(6);
              }
            } else if (res.data[0].event_name === "4*100M Mixed Relay") {
              setAthPerEve(3);
            } else if (res.data[0].event_name === "4*400M Mixed Relay") {
              setAthPerEve(3);
            } else {
              setAthPerEve(res.data[0].max_athlete_event);
            }
            setEvePerAth(res.data[0].max_event_athlete);
            setAgeName(res.data[0].age_category_name);
            setEventName(res.data[0].event_name);

            for (let i = 0; i < res.data.length; i++) {
              var clr = "";
              if (i == 0) {
                clr = "green";
              }
              arr1.push({
                event_id: res.data[i].tournament_event_id,
                event_name: res.data[i].event_name,
                id: res.data[i].id,
                max_athlete_event: res.data[i].max_athlete_event,
                max_event_athlete: res.data[i].max_event_athlete,
                age_category_name: res.data[i].age_category_name,
                background: clr,
              });
            }
            setEventlist(arr1);
            if (
              res.data[0].event_name === "4*100M Relay" ||
              res.data[0].event_name === "4*400M Relay" ||
              res.data[0].event_name === "Medley Relay"
            ) {
              if (id === "161" || id === "162") {
                loadlist(tid, res.data[0].tournament_event_id, 4,alink);
              } else {
                loadlist(tid, res.data[0].tournament_event_id, 6,alink);
              }
            } else if (res.data[0].event_name === "4*100M Mixed Relay") {
              loadlist(tid, res.data[0].tournament_event_id, 3,alink);
            } else if (res.data[0].event_name === "4*400M Mixed Relay") {
              loadlist(tid, res.data[0].tournament_event_id, 3,alink);
            } else {
              loadlist(
                tid,
                res.data[0].tournament_event_id,
                res.data[0].max_athlete_event,alink
              );
            }
          }
        }
      });
    }

    setAthletelist([]);
    const temp = new FormData();
    temp.append("state", localStorage.getItem("State"));
    temp.append("tourid", id);
    temp.append("cate", aid);
    athletelistbasedcategory(temp).then((res) => {
      if (res.status === true) {
        arr = [];
        setAthInfo(res.data);
        if (res.data.length !== 0) {
          for (let i = 0; i < res.data.length; i++) {
            var midname = "";
            if (res.data[i].middle_name !== null) {
              midname = res.data[i].middle_name + " ";
            }

            var last = "";
            if (res.data[i].last_name !== null) {
              last = res.data[i].last_name + " ";
            }
            arr.push({
              value: res.data[i].user_id,
              label:
                res.data[i].first_name +
                " " +
                midname +
                last +
                " (" +
                res.data[i].username +
                " )",
            });
          }
          setAthletelist(arr);
        }
      }
    });
  }
  const [errorMessage, setErrorMessage] = useState("");
  function athletesubmit() {
    setErrorMessage("");
    if (avalue !== "") {
      var error = "";
      // if (aclist.length !== 0) {
      //   if (apevent <= aclist.length) {
      //     error = "Maximum 3 athletes allowed per event";
      //     setErrorMessage(
      //       "Maximum " + aclist.length + " athletes allowed per event"
      //     );
      //   }
      // }

      if (error === "") {
        const tempDate = new FormData();
        var tcat = "";
        var tid = "";
        if (tlist.length !== 0) {
          for (let i = 0; i < tlist.length; i++) {
            if (tlist[i].active === "tabbtn active my-2 me-2") {
              tid = tlist[i].id;
              tcat = tlist[i].age_categories;
              break;
            }
          }
        }
        var eventid = "";
        if (elist.length !== 0) {
          for (let j = 0; j < elist.length; j++) {
            if (elist[j].event_name === ename) {
              eventid = elist[j].event_id;
            }
          }
        }

        tempDate.append("tournament_meet_id", id);
        tempDate.append("tournament_category", tcat);
        tempDate.append("category_id", tid);
        tempDate.append("eventslist", ename);
        tempDate.append("user_id", avalue.value);
        tempDate.append("districtname", localStorage.getItem("User"));
        if (urllink === "additionaladdathlete") {
          addstatedistrictaddathlete(tempDate).then((response) => {
            if (response.status === true) {
              setAthlete("");
              setShow(false);
              loadlist(tid, eventid, apevent,urllink);
            } else {
              setErrorMessage(response.message);
            }
          });
        } else {
          statedistrictaddathlete(tempDate).then((response) => {
            if (response.status === true) {
              setAthlete("");
              setShow(false);
              loadlist(tid, eventid, apevent,urllink);
            } else {
              setErrorMessage(response.message);
            }
          });
        }
      }
    } else {
      setErrorMessage("Please Select Your Athlete");
    }
  }

  const handleChange = (e) => {
    setAthlete(e);
    setAthName("");
    setAthDob("");
    setAthImage("");
    if (ainfo.length !== 0) {
      for (let i = 0; i < ainfo.length; i++) {
        if (e.value === ainfo[i].user_id) {
          if (ainfo[i].middle_name !== null) {
            setAthName(
              ainfo[i].first_name +
                " " +
                ainfo[i].middle_name +
                " " +
                ainfo[i].last_name
            );
          } else {
            setAthName(ainfo[i].first_name + " " + ainfo[i].last_name);
          }
          setAthDob(moment(ainfo[i].date_of_birth).format("DD-MM-YYYY"));
          setAthImage(imageUrl + fileString.athletephoto + ainfo[i].photograph);
        }
      }
    }
    // setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }

  return (
    <div>
      <div className="page-header text-center d-block">
        <div>
          <h2 className="main-content-title text-danger tx-28 mg-b-5">
            {tour}
          </h2>
        </div>

        <div className="d-block">
          <div className="justify-content-center">
            {tlist.map((data, i) => {
              return (
                <button
                  className={data.active}
                  key={i}
                  onClick={(e) => agelist(e, i)}
                >
                  {" "}
                  <h5 className="mb-0 tx-14">{data.age_categories}</h5>
                </button>
              );
            })}

            {/* <button className="tabbtn my-2 me-2">
              
              <h5 className="mb-0 tx-14">Under 20-Girls</h5>
            </button>
            <button className="tabbtn my-2 me-2">
              
              <h5 className="mb-0 tx-14">Men</h5>
            </button>
            <button className="tabbtn my-2 me-2">
              
              <h5 className="mb-0 tx-14">Women</h5>
            </button>  */}
          </div>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={4} xl={3}>
          <Card className="custom-card eventlistc">
            <Card.Header className="px-1 pt-0 pb-1">
              <div className="text-center headd">
                <h3 className="mb-0">
                  <label className="main-content-label tx-20 mb-0 text-danger">
                    Events
                  </label>
                </h3>
              </div>
            </Card.Header>
            {elist.map((data, i) => {
              return (
                <div key={i}>
                  {data.background !== "" && (
                    <Card.Footer
                      className="activeevent"
                      onClick={() => selectevent(data.event_name, i)}
                    >
                      <label className="custom-switch mb-0">
                        <a className="tx-14 mb-0 me-2 text-white">
                          {" "}
                          {data.event_name}{" "}
                        </a>
                        {/* <input
                      type="checkbox"
                      name="custom-switch-checkbox"
                      className="custom-switch-input"
                    />
                    <span className="custom-switch-indicator"></span> */}
                      </label>
                    </Card.Footer>
                  )}

                  {data.background === "" && (
                    <Card.Footer
                      className="text-center"
                      key={i}
                      onClick={() => selectevent(data.event_name, i)}
                    >
                      <label className="custom-switch mb-0">
                        <a className="tx-14 mb-0 me-2 text-dark">
                          {" "}
                          {data.event_name}{" "}
                        </a>
                        {/* <input
                      type="checkbox"
                      name="custom-switch-checkbox"
                      className="custom-switch-input"
                    />
                    <span className="custom-switch-indicator"></span> */}
                      </label>
                    </Card.Footer>
                  )}
                </div>
              );
            })}
          </Card>
        </Col>
        <Col lg={8} xl={9} className="eventlistd">
          <Row className="row-sm">
            <Col xl={12}>
              <Card className="custom-card">
                {/* <Card.Header className="py-3">
                  <Row>
                    <Col lg={12}>
                      <div className="d-flex">
                        <div>
                          <h5 className="tx-20 mb-0 text-dark tx-uppercase">
                            {agename}
                          </h5>
                          <code className="tx-14 text-danger mb-0">
                            (Maximum {apevent} athletes allowed per event)
                          </code>
                          <code className="tx-14 text-danger mb-0">
                            (Maximum {epathlete} event allowed per athletes)
                          </code>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Header> */}
                <Card.Body>
                  <div className="card-header bg-light py-3 mb-4">
                    <h4 className="tx-28 text-dark mb-0">
                      {agename} [<span className="text-danger">{ename}</span>]
                    </h4>
                    <h6 className="tx-18 text-muted mb-0">
                      Maximum {epathlete} event allowed per athletes{" "}
                      <span className="float-end text-danger tx-roboto tx-14">
                        PLEASE SELECT {apevent} ATHLETE(S) MAXIMUM.
                      </span>
                    </h6>
                  </div>
                  <Row className="row-sm mx-0 mt-0 border-bottom bg-white">
                    {aclist.map((list, j) => {
                      return (
                        <Col xl={3} lg={6} md={6} sm={6} key={j}>
                          <Card className="custom-card our-team border border-light shadow-base bg-light">
                            <Card.Body className="px-2 py-3">
                              <div className="picture avatar-lg online text-center">
                                <img
                                  alt="avatar"
                                  className="rounded-circle"
                                  src={
                                    imageUrl +
                                    fileString.athletephoto +
                                    list.photograph
                                  }
                                  onError={(e) => errorhandle(e)}
                                />
                              </div>
                              <div className="text-center mt-3">
                                {list.middle_name !== null && (
                                  <a>
                                    {list.first_name} {list.middle_name}{" "}
                                    {list.last_name}
                                  </a>
                                )}
                                {list.middle_name === null && (
                                  <a>
                                    {list.first_name} {list.last_name}
                                  </a>
                                )}
                                <p className="pro-user-desc text-danger mb-2 tx-18">
                                  ( {list.username} )
                                </p>
                                <div className="text-center tx-16 mb-0">
                                  DOB :{" "}
                                  {moment(list.date_of_birth).format(
                                    "DD-MM-YYYY"
                                  )}
                                </div>
                              </div>
                             
                                <a
                                className="deleteathlet"
                                onClick={() => deleteathlete(list.id)}
                              >
                                <i className="fa fa-close"></i>
                              </a>
                              
                              
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })}

                    {mcnt.map((data, k) => {
                      return (
                        <Col xl={3} lg={6} md={6} sm={6} key={k}>
                          <Card
                            className="custom-card our-team border border-light shadow-base bg-light"
                            onClick={setAShow}
                          >
                            <Card.Body px-2 py-3>
                              <div className="tx-26 d-block py-5 lineheight356 w-100 text-center">
                                {" "}
                                <i className="fa fa-plus-circle text-danger"></i>{" "}
                              </div>
                            </Card.Body>
                          </Card>

                          <Modal show={show}>
                            <Modal.Header>
                              <h6 className="rep-head">Slot</h6>
                              <i
                                className="fa fa-window-close fa-lg"
                                onClick={setAClose}
                                aria-hidden="true"
                              ></i>
                            </Modal.Header>
                            <Modal.Body className="mod-body">
                              <Select
                                placeholder="Select Athlete"
                                value={avalue}
                                options={alist}
                                onChange={handleChange}
                                isSearchable={true}
                              />
                              {aname !== "" && (
                                <Card className="custom-card our-team border border-light shadow-base bg-light">
                                  <Card.Body className="px-2 py-3">
                                    <div className="picture avatar-lg online text-center">
                                      <img
                                        alt="avatar"
                                        className="rounded-circle"
                                        src={aimage}
                                        onError={(e) => errorhandle(e)}
                                      />
                                    </div>
                                    <div className="text-center mt-3">
                                      <h5 className="pro-user-username text-dark mt-2 mb-0 tx-18">
                                        {aname}
                                      </h5>

                                      <div className="text-center tx-16 mb-0">
                                        DOB : {adob}
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Card>
                              )}
                              {errorMessage !== "" && (
                                <span className="text-danger">
                                  {errorMessage}
                                </span>
                              )}
                            </Modal.Body>
                            <Modal.Footer>
                              <a
                                className="btn btn-success btn-sm mb-0 me-2 tx-14"
                                onClick={athletesubmit}
                              >
                                Add Athlete
                              </a>
                              <button
                                onClick={setAClose}
                                className="text-center can-small"
                              >
                                Cancel
                              </button>
                            </Modal.Footer>
                          </Modal>
                        </Col>
                      );
                    })}
                  </Row>
                  {/* {aclist.length < apevent && (
                      <Col xl={3} lg={6} md={6} sm={6}>
                        <Card
                          className="custom-card our-team border border-light shadow-base bg-light"
                          onClick={setAShow}
                        >
                          <Card.Body px-2 py-3>
                            <div className="tx-26 d-block py-5 lineheight356 w-100 text-center">
                              {" "}
                              <i className="fa fa-plus-circle text-danger"></i>{" "}
                            </div>
                          </Card.Body>
                        </Card>
                        <Modal show={show}>
                          <Modal.Header>
                            <h6 className="rep-head">Slot</h6>
                            <i
                              className="fa fa-window-close fa-lg"
                              onClick={setAClose}
                              aria-hidden="true"
                            ></i>
                          </Modal.Header>
                          <Modal.Body className="mod-body">
                            <Select
                              placeholder="Select Athlete"
                              value={avalue}
                              options={alist}
                              onChange={handleChange}
                              isSearchable={true}
                            />
                          </Modal.Body>
                          <Modal.Footer>
                            <a
                              className="btn btn-success btn-sm mb-0 me-2 tx-14"
                              onClick={athletesubmit}
                            >
                              Add Athlete
                            </a>
                            <button
                              onClick={setAClose}
                              className="text-center can-small"
                            >
                              Cancel
                            </button>
                          </Modal.Footer>
                        </Modal>
                      </Col>
                    )} */}
                  {/* <Row>
                    <Col lg={12}>
                      <div className="d-flex px-2">
                        <div>
                          <label className="tx-14 mb-2 text-dark">
                            Select Athletes for this Event
                          </label>
                          <Select
                            placeholder="Select Athlete"
                            value={avalue}
                            options={alist}
                            onChange={handleChange}
                            isSearchable={true}
                          />
                        </div>
                        <div className="align-self-end ms-3">
                          <a
                            className="btn btn-success btn-sm mb-0 me-2 tx-14"
                            onClick={athletesubmit}
                          >
                            Add Athlete
                          </a>
                        </div>
                      </div>
                      {errorMessage !== "" && (
                        <span className="text-danger">{errorMessage}</span>
                      )}
                      <hr />
                      <Row className="row-sm mx-0 athlbox">
                        {aclist.map((list, j) => {
                          return (
                            <Col lg={6} className="ps-0 mb-3" key={j}>
                              <Card className="card-aside custom-card">
                                <img
                                  alt="media11"
                                  src={
                                    imageUrl +
                                    "athlete/photo/" +
                                    list.photograph
                                  }
                                  className="card-aside-column cover-image"
                                />
                                <Card.Body className="px-1 py-0 athdetail">
                                  <h5 className="list-content-label tx-dark tx-medium mg-b-10 tx-16">
                                    {list.first_name} {list.last_name}
                                  </h5>
                                  <h5 className="list-content-label tx-dark tx-medium mg-b-10 tx-16">
                                    <span>AFI UID -</span> {list.username}
                                  </h5>
                                  <h5 className="list-content-label tx-dark tx-medium mg-b-10 tx-16">
                                    <span>DOB -</span>{" "}
                                    {moment(list.date_of_birth).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </h5>
                                  <h5 className="list-content-label border-bottom-none tx-dark tx-medium mg-b-10 tx-16">
                                    <span>DOB -</span> Arvind Sirohi
                                  </h5>
                                </Card.Body>
                              </Card>
                              <div className="card-options">
                                <a
                                  className="btttn"
                                  onClick={() => deleteathlete(list.id)}
                                >
                                  <i className="fe fe-trash-2"></i>
                                </a>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row> */}
                </Card.Body>
                <Card.Footer className="text-center">
                  <label className="custom-switch text-center mb-0">
                    {urllink === "additionaladdathlete" && (
                      <>
                        <Link
                          to={"/district/additionalentrylist/" + id}
                          className="btn btn-danger btn-icon-text my-2 me-2"
                        >
                          Back
                        </Link>
                        <Link
                          to={"/district/additionalviewsummary/" + id}
                          className="btn btn-info btn-sm mb-0 me-2 tx-14"
                        >
                          View Summary
                        </Link>
                      </>
                    )}
                     {urllink !== "additionaladdathlete" && (
                      <>
                        <Link
                          to={"/district/entrylist/" + id}
                          className="btn btn-danger btn-icon-text my-2 me-2"
                        >
                          Back
                        </Link>
                        <Link
                          to={"/district/viewsummary/" + id}
                          className="btn btn-info btn-sm mb-0 me-2 tx-14"
                        >
                          View Summary
                        </Link>
                      </>
                    )}
                  </label>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Districtathlete;
